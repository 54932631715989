import React, {FC} from 'react';
import type { Product } from '../../interfaces/apiData/Product'
import {BACKEND_URL} from '../../constants'
import styles from './ProductCard.module.css'

interface Props {
    product: Product
}

const ProductCard: FC<Props> = ({ product }) => {
    return (
        <div className={styles.product_card}>
            <img src={`${BACKEND_URL}/assets/${product.image}?key=thumbnail`} alt={product.titre} />
                <h3><span style={{ padding: "0 10px" }}>{product.titre}</span></h3>
                <p className={styles.product_description}>{product.description}</p>
        </div>
    );
};

export default ProductCard;


