
import NavbarDesktop from './Desktop/NavbarDesktop'
import NavbarMobile from './Mobile/NavbarMobile'


import React, {useEffect, useState} from 'react';

const Navbar = () => {

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [viewportWidth, setViewportWidth] = useState(() => getWidth());

    useEffect(() => {
        const resizeListener = () => {
            console.log("RES: ", getWidth())
            // change width from the state object
            setViewportWidth(getWidth())
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, [viewportWidth])


    return (
        <>
            {viewportWidth > 1050 ? <NavbarDesktop /> : <NavbarMobile />}

        </>
    );
};

export default Navbar;
