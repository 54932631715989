import GalleryItem from '../../Components/Gallery/GalleryItem';
import type {GalleryImage} from '../../interfaces/apiData/GalleryImage';
import {BACKEND_URL} from '../../constants'
import styles from './Gallery.module.css'




import React, {useEffect, useState} from 'react';
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

const Gallery = () => {

    const [images, setImages] = useState<null | [GalleryImage]>(null)

    useEffect(() => {
        fetch(BACKEND_URL + '/items/gallery_images')
        .then(response => response.json())
        .then(responseData => setImages(responseData.data))
        .catch(error => console.error("error: ", error))
    }, [])


    return (
        <>
            <Navbar />
            <main className={styles.gallery}>
                <h2>Galerie photo</h2>
                <div className={styles.gallery_container}>
                    {images?.map( image => <GalleryItem  image={image} />)}
                </div>
            </main>
            <Footer />

        </>
    );
};

export default Gallery;



