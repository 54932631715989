import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from "./Routes/Home/Home";
import ProductList from "./Routes/Products/ProductList";
import Contact from "./Routes/Contact/Contact";
import Gallery from "./Routes/Gallery/Gallery";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact  />} />
                <Route path="/produits" element={<ProductList />} />
                <Route path="/galerie" element={<Gallery />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
