import React, {useState} from 'react';
import styles from './NavbarMobile.module.css'
import {Link} from "react-router-dom";

const NavbarMobile = () => {
    const [showMenu, setShowMenu] = useState(false)

    return (
        <div className={styles.nav_wrapper}>
            {showMenu
                ? <nav className={styles.nav_mobile}>
                    <div className={styles.nav_container}>
                        <div onClick={() => setShowMenu(false)} className={styles.close}>X</div>
                        <div className={styles.logo_container}>
                            <img src="img/logo.svg" alt="laitier de pipaix" />
                            <h1>Le laitier de Pipaix</h1>
                        </div>
                        <ul>
                            <li><Link to="/">Accueil</Link></li>
                            <li><Link to="/galerie">Gallerie</Link></li>
                            <li><Link to="/produits">Nos produits</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </nav>
                : <div onClick={() => setShowMenu(true)} className={styles.show_menu}>
                    <svg viewBox="0 0 100 80" width="40" height="40">
                        <rect width="100" height="20"></rect>
                        <rect y="30" width="100" height="20"></rect>
                        <rect y="60" width="100" height="20"></rect>
                    </svg>
                </div>
            }
        </div>
    );
};

export default NavbarMobile;

