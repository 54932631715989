import React, {useEffect, useState} from 'react';
import ProductCard from '../../Components/ProductCard/ProductCard'
import type {Product} from '../../interfaces/apiData/Product'
import {BACKEND_URL} from '../../constants'
import styles from './Product.module.css'
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";


const ProductList = () => {

    const [products, setProducts] = useState<null | [Product]>(null)

    useEffect(() => {
        fetch(BACKEND_URL + '/items/produits')
        .then(response => response.json())
        .then(responseData => setProducts(responseData.data))
        .catch(error => console.error("error: ", error))
    }, [])

    return (
        <>
            <Navbar />
            <main className={styles.products}>
                <h2>Nos produits</h2>
                <div className={styles.products_container}>
                    {products?.map(product => <ProductCard product={product}/>)}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ProductList;

