import React from 'react';
import styles from './Footer.module.css'

const Footer = () => {
    return (

        <footer className={styles.footer}>
            <section className={styles.image_wrapper}>
                <h3>Le laitier de Pipaix</h3>
                <div>
                    <img src="img/logo.svg" alt="laitier de pipaix" />
                </div>
            </section>
            <section>
                <h3>Nous contacter</h3>
                <p><a href="tel:+32489357443">+32 489 357 443</a></p>
                <p><a href="mailto:chevrerie.bio@pipaix.com">chevrerie.bio@pipaix.com</a></p>
            </section>
            <section className={styles.social}>
                <h3>Nos réseaux sociaux</h3>
                <p><img src="img/facebook.svg" alt="facebook" /><a href="https://www.facebook.com/laitier.depipaix.5">Laitier
                    De Pipaix</a></p>
            </section>
            <section>
                <h3>Nos heures d'ouverture</h3>
                <div className={styles.row}>
                    <p>Samedi</p>
                    <p>10h-12h</p>
                </div>
            </section>
        </footer>

    );
};

export default Footer;


