import React from 'react';
import styles from './Contact.module.css'
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";


const Contact = () => {
    return (
        <>
            <Navbar />
            <main className={styles.contact}>
                <h2>Contact</h2>
                <div className={styles.contact_wrapper}>
                    <div className={styles.map}>
                        <iframe
                            title="Map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10131.87294349898!2d3.5685053697753903!3d50.590543700000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2e2d161ea84b1%3A0xdf54db29bbd4fa41!2sChem.%20de%20Bariseul%205%2C%207904%20Leuze-en-Hainaut!5e0!3m2!1sfr!2sbe!4v1650528902098!5m2!1sfr!2sbe"
                            width="100%"
                            height="800"
                            style={{border: 0}}
                            loading="lazy">
                        </iframe>
                    </div>
                    <div className={styles.contact_info}>
                        <div className={styles.contact_item}>
                            <img src="img/phone.svg" alt="téléphone" />
                                <p><a href="tel:+32 489 357 443">+32 489 357 443</a></p>
                        </div>
                        <div className={styles.contact_item}>
                            <img src="img/mail.svg" alt="mail" />
                                <p><a href="maitol:frederic.verscheure@gmail.com">frederic.verscheure@gmail.com</a></p>
                        </div>
                        <div className={styles.contact_item}>
                            <img src="img/home.svg" alt="addresse" />
                                <p><a
                                    href="https://goo.gl/maps/xNovuv34VeCL9m3M9">
                                    Chemin de Bariseul, 5, 
                                    Pipaix, Belgique
                                    </a></p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Contact;