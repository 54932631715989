import React from 'react';
import styles from './Home.module.css'
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import {Link} from "react-router-dom";


const Home = () => {
    return (
        <>
        <Navbar />
        <div className={styles.home}>
            <header>
                <section className={styles.header_container}>
                    <h2>Bienvenue dans notre chèvrerie</h2>
                    <p>Envie de consommer éthique et respectueux des animaux?</p>
                    <Link to="/produits">
                        <button>Voir nos produits</button>
                    </Link>
                </section>
            </header>

            <section className={styles.quality}>
                <div className={styles.quality_container}>
                    <div className={styles.quality_item}>
                        <img src='/img/care.png' alt="respect animal" />
                            <h3>Respect de l'animal</h3>
                            <p>Nous aimons nos animaux. Chacun a son nom.</p>
                    </div>
                    <div className={styles.quality_item}>
                        <img src='/img/bio.png' alt="quality" />
                            <h3>Certifié bio</h3>
                            <p>certifié Bio par BE-BIO-01 CERTISYS</p>
                    </div>
                    <div className={styles.quality_item}>
                        <img src='/img/dechet.png' alt="0 dechet" />
                            <h3>Zero déchet</h3>
                            <p>Politique zero déchet.</p>
                    </div>
                    <div className={styles.quality_item}>
                        <img src='/img/hand.png' alt="quality" />
                            <h3>Circuit court!</h3>
                            <p>Nous nous efforçons de garder un circuit court tout au long de notre production.</p>
                    </div>
                </div>
            </section>

            <section className={styles.farm_lookup}>
                <div className={`${styles.farm_section} ${styles.farm_txt}`}>
                    <h3>Jeter un oeil à notre ferme</h3>
                    <p>Depuis 2017 la chèvrerie BIO de Pipaix se développe tout doucement. Accueillie dans la ferme familiale
                        reprise par son frère Christophe, Frédéric soigne les chèvres qui gambadent dans les prairies et donnent du
                        bon lait (transformé en différents fromages et yaourts). Presque zéro déchet. 
                        Le troupeau s'est installé le 15 août 2021 dans la nouvelle chèvrerie au chemin de Bariseul.</p>
                    <Link to="/galerie"><button className={styles.green_btn}>Galerie photo</button></Link>
                </div>
                <div className={styles.farm_section}>
                    <img src='/img/ferme-pipaix.jpg' alt="les chèvres" />
                </div>
            </section>

            <section className={styles.eco_farm}>
                <div className={styles.eco_farm_container}>
                    <div className={`${styles.eco_farm_pan} ${styles.eco_farm_left_pan}`}>
                        <img src="/img/milk-green.jpg" alt="zero déchet" />
                    </div>
                    <div className={`${styles.eco_farm_pan} ${styles.eco_farm_right_pan}`}>
                        <h3>Une ferme eco responsable</h3>
                        <p>
                            Nous travaillons en total respect de nos animaux ainsi que de l'environnement.
                            Nos produits sont certifiés Bio, produit et vendu en Belgique.
                        </p>
                    </div>
                </div>
            </section>

            <section className={styles.know_more}>
                <h3>Envie d'en savoir plus?</h3>
                <Link to="/contact"><button className={styles.green_btn}>CONTACTEZ NOUS</button></Link>
            </section>

            <section className={styles.map}>
                <iframe
                    title="Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10131.87294349898!2d3.5685053697753903!3d50.590543700000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2e2d161ea84b1%3A0xdf54db29bbd4fa41!2sChem.%20de%20Bariseul%205%2C%207904%20Leuze-en-Hainaut!5e0!3m2!1sfr!2sbe!4v1650528902098!5m2!1sfr!2sbe"
                    width="100%"
                    height="450"
                    style={{border:0}}
                    loading="lazy">
                </iframe>
            </section>
        </div>
        <Footer />
    </>
    );
};

export default Home;

