import React from 'react';
import styles from './NavbarDesktop.module.css'
import {Link} from "react-router-dom";

const NavbarDesktop = () => {
    return (
        <nav className={styles.nav_desktop}>
            <div className={styles.nav_container}>
                <Link to="/">
                    <div className={styles.logo_container}>
                        <img src="img/logo.svg" alt="laitier de pipaix" />
                        <h1>Le laitier de Pipaix</h1>
                    </div>
                </Link>

                <ul>
                    <li><Link to="/">Accueil</Link></li>
                    <li><Link to="/galerie">Galerie</Link></li>
                    <li><Link to="/produits">Nos produits</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </div>
        </nav>
    );
};

export default NavbarDesktop;

