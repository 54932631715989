import React, {FC, useState} from 'react';
import { BACKEND_URL } from '../../constants'
import type { GalleryImage } from '../../interfaces/apiData/GalleryImage'
import styles from './GalleryItem.module.css'

interface Props {
    image: GalleryImage
}

const GalleryItem: FC<Props> = ({ image }) => {

    const [showFullImage, setShowFullImage] = useState(false)

    return (
        <main className={styles.gallery_item}>
            <img onClick={() =>setShowFullImage(true)}
                 className={styles.thumbnail}
                 src={`${BACKEND_URL}/assets/${image.image}?key=thumbnail`}
                 alt={image.description}
            />
                {showFullImage && <>
                <div className={styles.full_size_bg} />
                <div onClick={() => setShowFullImage(false)}
                     className={styles.full_size_img_container}>
                    <img className={styles.full_size_img} src={`${BACKEND_URL}/assets/${image.image}`} alt={image.description} />
                        <div className={styles.close}>X</div>
                </div>
            </>}
        </main>
    );
};

export default GalleryItem;





